body {
    background-image: url("./images/background-agil.jpg");
    background-repeat: no-repeat;
    background-size: auto;
    
}

@font-face {
    font-family: "NoirPro";
    src: local("NoirPro"),
     url("./fonts/NoirPro/NoirPro-Regular.otf") format("truetype");
    /* font-weight: bold; */
}

@font-face {
    font-family: "Signatria";
    src: local("Signatria"),
     url("./fonts/Signatria/Signatria.ttf") format("truetype");
}